import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';
import Zoom from "react-reveal/Zoom";

const WhatGPT3 = () => (
  <Zoom duration={2000}>
  <div className="gpt3__whatgpt3 section__margin" id="Problem">
  <div className="gpt3__whatgpt3-feature">
      <Zoom duration={2000}>
      <Feature style = "position:relative; left:-80px"title="Problem" text="" />
      </Zoom>
    </div>
    <Zoom duration ={2000}>
    <div className="gpt3__whatgpt3-heading">
     
      <h1 className="gradient__text">There is no fast, secure, and easy to use way to bridge assets between the Tron and ETH networks. We aim to solve that.</h1>
      
    </div>
    </Zoom>

 
    
    <div className="gpt3__whatgpt3-feature">
      
        <Zoom duration={2000}>
      <Feature style  = "position:relative;" margin-left= "-2rem"title="Solution" text="" />
    </Zoom>
    
    </div>
    <Zoom duration ={2000}>
    <div className="gpt3__whatgpt3-heading">
     
     <h2 className="gradient__text">
Build a fully web 3 enabled decentralized bridge that allows users to swap USDT and other assets between the two chains without using a 3rd party. 
</h2>
   
   </div>
      </Zoom>
  
  </div>
  </Zoom>
);

export default WhatGPT3;