import React from "react";
import ScrollToTop from "react-scroll-to-top";
import {
  Blog,
  Possibility,
  Features,
  WhatGPT3,
  Header,
  Footer,
} from "./containers";
import { CTA, Navbar } from "./components";
import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/react";

import { configureChains, createClient, WagmiConfig } from "wagmi";

import { arbitrum, mainnet, polygon } from "wagmi/chains";

import "./App.css";

import Fade from "react-reveal/Fade";

const chains = [arbitrum, mainnet, polygon];

// Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: "3ddcd92acf36fdbdb2c48445d4d3ce91" }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({
    projectId: "3ddcd92acf36fdbdb2c48445d4d3ce91",
    version: "1", // or "2"
    appName: "web3Modal",
    chains,
  }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

function App() {
  return (
    <>
      <Fade>
        <WagmiConfig client={wagmiClient}>
          <div className="App">
            <div className="gradient__bg">
              <Navbar />
              <Header />
            </div>
            <WhatGPT3 />
            <Possibility />
            <Features />
            <CTA />
            <Footer />
          </div>
        </WagmiConfig>
        <Web3Modal
          projectId="3ddcd92acf36fdbdb2c48445d4d3ce91"
          ethereumClient={ethereumClient}
        />
      </Fade>
      <ScrollToTop smooth color="#81AFDD" />
    </>
  );
}

export default App;
