import React  from 'react';
import people from '../../assets/people.png';
import si from '../../assets/si.png';
import './header.css';
import Zoom from "react-reveal/Zoom";



const Header = () => (
  
  
  <div className="gpt3__header section__padding " id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text"> The Premiere ERC/TRC Bridge for USDT Swaps
      
      </h1>
      <p>
      Fully USDD compatible
      </p>

      
    </div>
  
  <Zoom>
    <div className="gpt3__header-image">
      <a>
      <img src={si} />
      </a>
    </div>
    </Zoom>
  </div>
  
  
)



export default Header;
