
import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../logo.png';
import './navbar.css';


const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);


return (
<div className="gpt3__navbar">
<div className="gpt3__navbar-links">
<div className="gpt3__navbar-links_logo">

<img src={logo} />

</div>
<div className="gpt3__navbar-links_container">
<p><a href="#home">Home</a></p>
<p><a href="#Problem">Problem</a></p>
<p><a href="#Roadmap">Roadmap</a></p>
<p><a href="#Thefuture">The Future</a></p>
</div>
</div>
<div className="gpt3__navbar-sign">
<a href="https://selene-trex.netlify.app/">
        <button type="button">Launch Dapp</button></a>
</div>
<div className="gpt3__navbar-menu">
{toggleMenu
? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
: <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
{toggleMenu && (
<div className="gpt3__navbar-menu_container scale-up-center">
<div className="gpt3__navbar-menu_container-links">
<p><a href="#Problem">Problem</a></p>
<p><a href="#Roadmap">Roadmap</a></p>
<p><a href="#Thefuture">The Future</a></p>
</div>
<div className="gpt3__navbar-menu_container-links-sign">

      <a href="https://selene-trex.netlify.app/">
        <button type="button">Launch Dapp</button></a>
      </div>
</div>

)}
</div>
</div>
);
};

export default Navbar;
