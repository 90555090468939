import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
     
      <h3>Come be a part of our community & explore the future of Tron.</h3>
    </div>
    <div className="gpt3__cta-btn">
        <a href="https://discord.gg/G7KCSKBrpP">
      <button onclick="window.location.href='https://w3docs.com';" type="button">Join Our Discord</button></a>
    </div>
  </div>
);

export default CTA;