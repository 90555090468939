 
import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';
import Zoom from "react-reveal/Zoom";

const Features = () => (
  <Zoom duration={2000}>
  <div className="gpt3__whatgpt3 section__margin" id="Thefuture">
  <div className="gpt3__whatgpt3-feature">
      <Zoom duration={2000}>
      <Feature style = "position:relative; left:-80px"title="The&nbsp;&nbsp;Future" text="" />
      </Zoom>
    </div>

    <div className="gpt3__whatgpt3-heading">
      <Zoom duration={2500}>
      <h1 className="gradient__text">The Future of Tron and Ether interconnectivity begins here at Selene Labs</h1>
      </Zoom>
    </div>

    <div className="gpt3__whatgpt3-container">
    <Zoom duration={3000}>
      <Feature title="Secure" text="Fully open source. Our team is composed of some of the brightest minds in bridging technology, our code is checked and rechecked continuously.  Selene Labs has set up a SAFU fund to protect users from hacking and loss of funds, which we never hope to deploy, but it’s there for your piece of mind. The fund can always be checked on-chain. " />
      </Zoom>
      <Zoom duration ={3000}>
      <Feature title="Fast" text="Both ERC and TRC chains have an extremely fast throughput, most transactions clear within seconds on the bridge. Navitagte quickly between TRC and ERC wallets with one click, transactions appear almost immediately." />
      </Zoom>
      <Zoom duration ={3000}>
      <Feature title="Necessity " text="There is no Web3 bridge between the ERC and TRC networks. Currently to swap between chains, you must deposit to a fully KYC’d exchange and then withdraw on a different network, lead time here can be hours and risk is high. " />
      </Zoom>
      
    </div>
  
  </div>
  </Zoom>
);

export default Features;