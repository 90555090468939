import React from 'react';
import gpt3Logo from '../../logo.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
      
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Socials</h4>
        <a href="https://twitter.com/selene_bridge">
        <p>Twitter</p>
        </a>
        <a href="https://www.youtube.com/@selenelabs">
        <p>Youtube</p>
        </a>
        <a href="https://t.me/+ClSjsccfzps5ODMx">
        <p>Telegram</p>
        </a>
        <a href="https://discord.gg/G7KCSKBrpP">
        <p>Discord</p></a>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Legal</h4>
        <a href="https://www.termsfeed.com/live/80bcca06-7500-4237-a2fa-ce97dbc6318b">
        <p>Privacy Policy</p>
        </a>
        <a href='https://www.termsfeed.com/live/221bdeac-cc2b-4f03-bcc5-11a9d78e8c99'>
        <p>Terms & Conditions </p>
        </a>
        
      </div>
      
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2023 Selene Labs. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;